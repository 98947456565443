@import '../../../styles/mixins';

.common-not-supported-browser {
  padding: 5vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  color: white;
  background-color: #3897e3;
  padding-right: 10vw;
  padding-left: 10vw;
  justify-content: center;

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;

    .simple-text {
      font-size: 3vw;
      color: white;
      text-decoration: underline;
      margin-bottom: 3vw;
    }

    .title {
      font-weight: 200;
      font-size: 3vw;
      margin-bottom: 3vw;
      text-align: center;
      line-height: 3vw;
    }

    .browser-links {
      display: flex;
      align-content: space-around;
      font-size: 1.5vw;
      flex-wrap: wrap;

      .browser-link {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 3vw;
        margin-left: 3vw;
        margin-right: 3vw;

        svg {
          font-size: 4vw;
        }
      }
    }

    .text {
      font-size: 1.5vw;
      margin-bottom: 3vw;
      font-weight: 100;
      text-align: center;
    }

    .link {
      font-size: 1.5vw;
      margin-top: 2vw;
      margin-bottom: 1vw;
      color: white;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .footer {
    color: black;
  }
}
