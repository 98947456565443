@import '../../styles/mixins';

.conference-stream-viewer {
  height: 100%;
  width: 100%;
  position: relative;
  background-color: black;
}

.no-stream {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 15rem 5rem 15rem 5rem;
  background-color: white;
  text-align: center;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}
