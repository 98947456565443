@import '../../styles/mixins';

.case-bundles-page {
  border-radius: 4px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), 0 0 0 1px rgba(63, 63, 68, 0.1);
  background-color: #fff;
  height: 500px;
  margin: 30px;
  text-align: center;
  line-height: 500px;
}
