@import '../../styles/mixins';

.case-custom-drag-layer {
  .card {
    display: table-row-group;
    border: 1px solid transparent;
    border-radius: 5px;
    overflow: hidden;
    width: 25rem;

    td {
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    td:last-child {
      width: 100%;
    }
  }

  .card-dragged {
    position: absolute;
    transform-origin: bottom right;
    -webkit-backface-visibility: hidden;
  }
}
