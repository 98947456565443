@import '../../styles/mixins';

.case-layout {
  height: 100%;
  display: flex;

  .main-panel {
    background: rgba(203, 203, 210, 0.15);
    position: relative;
    height: 100%;
    padding-bottom: 2rem;
    flex-grow: 1;
    flex-shrink: 1;
    width: calc(100% - 280px);

    -webkit-transition-property: top, bottom;
    transition-property: top, bottom;
    -webkit-transition-duration: 0.2s, 0.2s;
    transition-duration: 0.2s, 0.2s;
    -webkit-transition-timing-function: linear, linear;
    transition-timing-function: linear, linear;
    -webkit-overflow-scrolling: touch;

    > .content {
      height: calc(100% - 61px);
      width: 100%;
    }

    > .footer {
      border-top: 1px solid #e7e7e7;
    }

    .navbar {
      margin-bottom: 0;
    }
  }

  // .fullSizePanel {
  //   width: 100%;
  // }

  .collapse-icon {
    position: absolute;
    z-index: 2;
    background-color: #698cef;
    left: 240px;
    top: 1.4rem;
    border: solid #ffffff 1px;
    width: 25px;
    height: 25px;
    min-height: 25px;
    box-shadow: none;
    &:hover {
      background-color: #698cef;
      box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 6px 10px 0px rgb(0 0 0 / 14%),
        0px 1px 18px 0px rgb(0 0 0 / 12%);
    }
  }

  .expand-icon {
    background-color: white;
    border: solid #4567e5 1px;
    width: 25px;
    height: 25px;
    margin-top: 1.4rem;
    margin-left: 0.5rem;
    min-height: 25px;
    box-shadow: none;
    &:hover {
      background-color: white;
      box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 6px 10px 0px rgb(0 0 0 / 14%),
        0px 1px 18px 0px rgb(0 0 0 / 12%);
    }
  }

  .nav-with-collapse {
    display: flex;
    flex-direction: row;
    background-color: #ffffff;
  }
  .nav-flex {
    flex-grow: 1;
    padding: 0px !important;
  }

  @media (max-width: 991px) {
    .main-panel {
      width: 100%;
      max-width: 100%;
      @include transform-translate-x(0px);
      @include transition(0.33s, cubic-bezier(0.685, 0.0473, 0.346, 1));
      left: 0;
    }
  }

  .sidebar,
  body > .navbar-collapse {
    &:after,
    &:before {
      display: block;
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 2;
    }

    &:before {
      opacity: 0.33;
      background: #000000;
    }

    &:after {
      @include icon-gradient($black-color-top, $black-color-bottom);
      z-index: 3;
      opacity: 1;
    }

    &[data-image]:after,
    &.has-image:after {
      opacity: 0.77;
    }

    &[data-color='blue']:after {
      @include icon-gradient($new-dark-blue, $blue-color-bottom);
    }
    &[data-color='azure']:after {
      @include leftRight-gradient($new-blue, $azure-color-bottom);
    }
    &[data-color='green']:after {
      @include icon-gradient($new-green, $green-color-bottom);
    }
    &[data-color='orange']:after {
      @include icon-gradient($new-orange, $orange-color-bottom);
    }
    &[data-color='red']:after {
      @include icon-gradient($new-red, $red-color-bottom);
    }
    &[data-color='purple']:after {
      @include icon-gradient($new-purple, $purple-color-bottom);
    }
  }
}
