@import '../../styles/mixins';

.case-theatre {
  height: 100%; // ABSOLUTE MUST FOR STUPID ACUSOFT VIEWER TO LOAD PAGE
  width: 100%;

  > .content {
    height: calc(100% - 61px);
    width: 100%;
    padding-bottom: 2rem;

    &.full-screen {
      height: 100%;
      padding-bottom: 0;
    }
  }

  .navbar-toggler {
    display: none !important;
  }

  .nav-bar-links {
    display: inherit !important;
  }

  .d-l-none.shown {
    background: rgba(255, 255, 255, 0.3);
    border-radius: 25%;
    -webkit-box-shadow: inset 0px 0px 0px 1px #292929;
    -moz-box-shadow: inset 0px 0px 0px 1px #292929;
    box-shadow: inset 0px 0px 0px 1px #292929;
  }
}

.theatre-video {
  display: flex;
  flex-direction: column;
  position: relative;
}
