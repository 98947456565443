@import '../../styles/mixins';

.react-toast-notifications__toast {
  background-color: white;
  border-radius: 0.25rem;
  box-shadow: 0 3px 1rem rgba(0, 0, 0, 0.175);
  display: flex;
  margin-bottom: 1rem;
  width: 30rem;

  .btn.btn-link {
    padding: 0;
  }

  .react-toast-notifications__toast__countdown {
    animation-name: react-toast-notifications__toast__countdown_animation;
    animation-timing-function: linear;
    background-color: rgba(255, 255, 255, 1);
    top: 0;
    height: 0;
    left: 0;
    position: absolute;
    width: 100%;
  }

  .react-toast-notifications__toast__icon-wrapper {
    flex-shrink: 0;
    position: relative;
    overflow: hidden;
    text-align: center;
    padding: 1rem;
    align-self: center;
    font-size: 1.5rem;
  }

  .react-toast-notifications__toast__icon {
    position: relative;
    z-index: 1;
  }

  .react-toast-notifications__toast__content-wrapper {
    display: flex;
    flex-grow: 1;
    padding: 0.5rem 0.75rem;
    padding-left: 0;

    .react-toast-notifications__toast__content {
      flex-grow: 1;
      min-height: 5rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .react-toast-notifications__toast__dismiss-button-container {
      margin-left: 0.75rem;

      .react-toast-notifications__toast__dismiss-button {
        cursor: pointer;
        flex-shrink: 0;
        opacity: 0.75;
        padding: 0;
        transition: opacity 150ms;
        align-self: center;
        font-size: 1.4rem;
        font-weight: 100;
        color: #aaaaae;

        &:hover {
          opacity: 1;
        }
      }
    }
  }

  .react-toast-notifications__toast__side {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
    flex-shrink: 0;
    padding-bottom: 1rem;
    padding-top: 1rem;
    position: relative;
    overflow: hidden;
    text-align: center;
    width: 0.35rem;
  }
}

@keyframes react-toast-notifications__toast__countdown_animation {
  from {
    height: 0%;
  }
  to {
    height: 100%;
  }
}
