@import '../../styles/mixins';

.sidebar {
  .sidebar-wrapper {
    height: calc(100vh - 125px);
  }

  .nav {
    &.all-documents {
      position: absolute;
      width: 100%;
      bottom: 10px;
      z-index: 4;
      padding: 0 10px;
      margin: 0;

      a {
        background: rgba(255, 255, 255, 0.14);
        opacity: 1;
        color: #ffffff;
      }
    }

    &.court-bundles {
      margin-top: 0;
      border-radius: 0.25rem;
      display: inherit;

      .case-sidebar-item {
        margin-left: auto;
        margin-right: auto;
        padding: 0 5px;
      }

      & > div {
        width: 100%;
        text-align: center;
      }

      &.drag {
        box-shadow: 0 0 2pt 1pt rgba(255, 255, 255, 0.5);
      }
    }
  }

  .divider-with-icon {
    display: flex;
    height: 30px;
    padding: 0;
    margin-bottom: -14px;

    .divider:first-of-type {
      height: 0;
      width: 70%;
      margin-right: 10px;
    }
    .icon {
      font-size: 1.5rem;
      width: 30px;
      padding: 0.2rem;
      color: rgba(255, 255, 255, 0.4);
      text-align: center;
    }
    .divider:last-of-type {
      height: 0;
      width: calc(30% - 50px);
      margin-left: 10px;
    }
  }

  .divider {
    border-top: 1px solid rgba(255, 255, 255, 0.4);
    z-index: 2;
    margin-top: 15px;
  }

  .titleButton {
    :hover {
      text-decoration: none;
      cursor: pointer;
      background: rgba(255, 255, 255, 0.13);
    }
    .nav-title-button {
      padding: 0.5rem 0.7rem;
    }
  }
  .activeTitleButton {
    background: rgba(255, 255, 255, 0.13) !important;
  }

  .title {
    margin: 20px 15px 0px 0px;
    width: 100%;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: none;
    background: none;
    color: inherit;

    svg {
      font-size: 1.25rem;
      cursor: pointer;
      color: rgba(255, 255, 255, 0.75);
      transition: 0.3s all;
      -moz-transition: 0.3s all;
      -ms-transition: 0.3s all;
      -o-transition: 0.3s all;
      -webkit-transition: 0.3s all;

      &:hover {
        color: rgba(255, 255, 255, 1.4);
        transition: 0.3s all;
        -moz-transition: 0.3s all;
        -ms-transition: 0.3s all;
        -o-transition: 0.3s all;
        -webkit-transition: 0.3s all;
      }
    }
  }

  .add {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin: 5px;
    margin-left: auto;
    margin-right: auto;
    padding: 0 5px;
    cursor: pointer;
    color: rgba(255, 255, 255, 0.75);
    border-radius: 0.25rem;
    transition: 0.3s all;
    -moz-transition: 0.3s all;
    -ms-transition: 0.3s all;
    -o-transition: 0.3s all;
    -webkit-transition: 0.3s all;

    svg {
      margin-right: 10px;
    }

    &:hover {
      box-shadow: 0 0 2pt 1pt rgba(255, 255, 255, 0.5);
      color: rgba(255, 255, 255, 1.4);
      transition: 0.3s all;
      -moz-transition: 0.3s all;
      -ms-transition: 0.3s all;
      -o-transition: 0.3s all;
      -webkit-transition: 0.3s all;
    }
  }
}
