@import '../../styles/mixins';

.video-content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.dropdown-item-static {
  pointer-events: none;
  &:hover {
    background-color: white !important;
  }
}

.customLink {
  .nav-link {
    color: #6c757c;
    padding: 0;
    &::after {
      display: none;
    }
  }
}

.highlightCustomLink {
  .nav-link {
    padding: 0;
    &::after {
      display: none;
    }
  }
}
