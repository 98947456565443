@import '../../styles/mixins';

.common-actions-button {
  .modifier {
    text-transform: none;
    right: 0 !important;
    left: auto !important;
    margin: 0 !important;

    .dropdown-item svg {
      margin-right: 5px;
      width: 17px;
    }
  }
}
