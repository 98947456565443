@import '../../styles/mixins';

.page-not-found {
  padding: 5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  color: white;
  background-color: #3897e3;

  .simple-text {
    font-size: 2rem;
    color: white;
    text-decoration: underline;
    margin-bottom: 2rem;
  }

  .title {
    font-weight: 200;
    font-size: 5rem;
    margin-bottom: 2rem;
  }

  .image {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 3rem;
  }

  .text {
    font-size: 2rem;
    margin-bottom: 2rem;
    font-weight: 100;
  }

  .link {
    font-size: 1.5rem;
    margin-bottom: 2rem;
    color: white;

    &:hover {
      text-decoration: underline;
    }
  }

  .footer {
    color: black;
  }
}
