@import '../../../styles/mixins';

.viewing-acusoft-viewer {
  width: 100%;
  height: 100%;
  // position: relative;

  @-webkit-keyframes pcc-anim-load {
    0% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
    100% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
  }
  @keyframes pcc-anim-load {
    0% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
    100% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
  }

  .pccPageListItemLoading {
    margin-top: 60%;
    width: 10%;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: pcc-anim-load;
    animation-name: pcc-anim-load;
  }

  .pcc-page-list {
    height: 100%;
    margin: 0 auto;
  }
}
