@import '../../../styles/mixins';

.case-table-wrapper,
.case-viewer-wrapper {
  margin: 0;
  padding: 5px 0 0 0; //20px 15px 30px 15px;
  height: 100%;
  display: flex;

  .breadcrumb {
    a {
      color: #2fb3f2;
    }
    margin-bottom: 0;
    padding: 0;
    background-color: inherit;
  }

  .secondRow {
    height: calc(100% - 55px);
    display: flex;
  }

  .filterChipSection {
    display: flex;
    & .ps__rail-x {
      z-index: 2;

      & .ps__thumb-x {
        height: 5px;
      }
    }
  }

  @media (max-width: 500px) {
    .secondRow {
      height: calc(100% - 75px);
    }
  }

  .card {
    height: 100%;
    background-color: #fff;

    .card-title {
      color: #333;
      margin-top: 15px;
      margin-left: 15px;
    }

    .card-subtitle {
      color: #9a9a9a;
      margin-left: 15px;
    }

    .card-body {
      padding: 0;
    }
  }

  .table-responsive {
    margin: 0;
  }

  table {
    border-style: hidden;
    margin-bottom: 0;

    .metadata,
    .pageCount {
      text-align: center;
    }

    .code,
    .sortOrder,
    .sequence,
    .private,
    .metadata,
    .alternateName,
    .notes {
      display: none;
    }

    @media (max-width: 991px) {
      .sequence {
        display: none;
      }
    }

    @media (max-width: 500px) {
      .type,
      .pageCount {
        display: none;
      }
    }
  }

  .table-striped > tbody > tr:nth-of-type(odd) {
    background-color: transparent;
    // background-color: rgba(203, 203, 210, 0.15);
  }

  .table-hover > tbody tr:hover {
    background-color: rgba(0, 0, 0, 0.075);
  }

  .loading {
    animation: glow 0.8s infinite alternate;
    height: 100%;
  }

  @keyframes glow {
    from {
      box-shadow: 0 0 4px -2px #7ba0f4;
    }
    to {
      box-shadow: 0 0 4px 2px #7ba0f4;
    }
  }

  .folder-footer {
    display: flex;
    width: 100%;
    justify-content: space-between;

    .total-page-count {
      width: 50%;
      font-size: 0.85rem;
      color: #9a9a9a;
      text-align: left;
      opacity: 0.75;
    }

    .document-count {
      width: 50%;
      font-size: 0.85rem;
      color: #9a9a9a;
      text-align: right;
      opacity: 0.75;
    }

    .total-document-size {
      width: 50%;
      font-size: 0.85rem;
      color: #9a9a9a;
      text-align: center;
      opacity: 0.75;
    }
  }

  .shortcutsText {
    font-style: italic;
    padding-left: 0.5rem;
    flex-grow: 1;
    text-align: end;
    margin-right: 1rem;
  }
}
.case-table-wrapper {
  transition: margin-right 0.3s ease;
}

/* Shift the main content when SideDrawer is open */
.case-table-wrapper.content-shift {
  margin-right: 300px;
}

@media (min-width: 1024px) {
  .case-table-wrapper.content-shift {
    margin-right: 400px;
  }
}