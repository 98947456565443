@import '../../../styles/mixins';

.case-viewer-wrapper {
  .actions-buttons {
    height: 23px;
    font-size: 1.27rem;
    align-items: center;
    align-content: center;
    justify-content: center;
    

    svg {
      margin-left: 1rem;
      cursor: pointer;
    }
  }

  .sidebar-view {
    display: none;
  }
}
