@import '../../styles/mixins';

.sticky-footer {
  position: sticky;
}

.settings-content {
  background-color: #f7f7f8;
  height: 100%;

  .navbar-toggler {
    display: none !important;
  }

  .nav-bar-links {
    display: inherit !important;
  }

  a {
    color: #4567e5;
  }
}
