@import '../../styles/mixins';

.home-cases {
  padding-top: 1rem;
  padding-bottom: 1rem;
  display: flex;
  align-content: space-around;
  justify-content: center;
  font-size: 1.5rem;
  overflow: auto;
  margin-left: 4rem;
  margin-right: 4rem;
  flex-wrap: wrap;

  .case-card-admin,
  .new-case {
    cursor: pointer;
    padding: 1rem;
    padding-bottom: 0.5rem;
    height: auto;
    width: 20rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    color: #3a68ed;
    text-align: center;

    svg {
      font-size: 3rem;
    }

    &.btn-link {
      svg {
        font-size: 2.75rem;
        margin-bottom: 0.25rem;
      }
    }

    span {
      line-height: 1.2em;
      max-height: 2.4em;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
      font-size: 1.5rem;
    }
  }

  .case-card {
    padding: 0;
    box-shadow: 0 0.5rem 0.5rem 0.4rem rgba(0, 0, 0, 0.2);
    margin: 1.5rem;
    display: flex;
    width: 40rem;
    border-radius: 0.8rem;

    .links {
      width: 15rem;
      align-self: center;
    }

    .title {
      height: 100%;
      padding: 0;
      background-color: #3a68ed;
      color: white;
      width: 25rem;
      text-align: left;
      padding: 1rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      font-weight: 700;
      font-size: 1.5rem;
      line-height: 1.7rem;
      letter-spacing: 0.48px;
      border-bottom-right-radius: 0.8rem;
      border-top-right-radius: 0.8rem;
      &:hover {
        cursor: pointer;
        text-decoration-line: underline;
      }
    }
  }

  .case-card-link {
    color: #0b022e;
    font-weight: 100;
    width: 100%;
    padding: 0.5rem 1.5rem;
    text-decoration: none;
    display: flex;
    align-items: center;
    text-align: left;
    font-size: 1.3rem;
    font-weight: 500;
    line-height: 1.9rem;
    letter-spacing: 0.54px;
    & > span {
      color: #3a68ed;
      overflow: initial;
      svg {
        font-size: 3rem;
        width: 100%;
        height: 100%;
        margin-right: 0.5rem;
      }
    }

    &:hover {
      background-color: #f1f1f1;
      cursor: pointer;
    }
  }
}

.btn-container {
  font-size: 1.4rem;
  font-family: sans-serif;
  background-color: white;
}
.btn-container:hover {
  background-color: #f1f1f1;
}
.justify-message {
  width: 100%;
  text-align: center;
}

@media screen and (max-width: 550px) {
  .home-cases {
    margin: 0%;
    font-size: 1.2rem;
    justify-content: center;

    .case-card {
      width: auto;
    }
    .case-card-link {
      letter-spacing: 0;
      font-size: 1.2rem;
    }
    .links {
      width: 13.5rem !important;
    }
    .title {
      width: 13.5rem !important;
      font-size: 1.2rem !important;
      letter-spacing: 0 !important;
    }
  }
}
