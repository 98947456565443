@import '../../../styles/mixins';

.sidebar {
  .nav-bar-links > div > .dropdown,
  .case-sidebar-item > .dropdown {
    & > .dropdown-menu {
      display: block;
      visibility: hidden;
      opacity: 0;
      background-color: transparent;
      will-change: transform;
      -webkit-box-shadow: none;
      box-shadow: none;
      border: none;
      padding: 0;
      margin: 0;
      width: calc(100% - 5px);
      -webkit-transform: translate3d(0, 0, 0) !important;
      -moz-transform: translate3d(0, 0, 0) !important;
      -o-transform: translate3d(0, 0, 0) !important;
      -ms-transform: translate3d(0, 0, 0) !important;
      transform: translate3d(0, 0, 0) !important;
    }
    &.show > .dropdown-menu {
      z-index: 500;
      position: relative !important;
      visibility: visible;
      opacity: 100;
      float: none;
      -webkit-transform: translate3d(10px, 0, 0) !important;
      -moz-transform: translate3d(10px, 0, 0) !important;
      -o-transform: translate3d(10px, 0, 0) !important;
      -ms-transform: translate3d(10px, 0, 0) !important;
      transform: translate3d(10px, 0, 0) !important;
      @include transition($slow-transition-time, $transition-bezier);
    }

    .dropdown-toggle .nav-link {
      padding-right: 2rem;
    }

    .dropdown-toggle .nav-link::after {
      position: absolute;
      right: 1.255em;
      content: '';
      border-top: 0.3em solid;
      border-right: 0.3em solid transparent;
      border-bottom: 0;
      border-left: 0.3em solid transparent;
      top: calc(50% - 0.15rem);
    }
  }

  .nav-bar-links,
  .case-sidebar-item {
    margin: 5px;

    a > div > p {
      margin: inherit;
      color: inherit;
      line-height: inherit;
      font-size: inherit;
      font-weight: inherit;
      text-transform: inherit;
      text-align: inherit;
    }

    a,
    div > a,
    div > div > a {
      margin: 0;
      color: #ffffff;
      line-height: 20px;
      font-size: 12px;
      font-weight: 600;
      text-transform: uppercase;
      text-align: left;
      white-space: inherit;

      opacity: 0.86;
      border-radius: 4px;
      display: flex;
      justify-content: space-between;
      position: relative;
      align-items: center;

      & > div {
        display: flex;
        align-items: center;

        svg {
          margin-right: 5px;
          width: 17px;
        }
      }

      &.drag {
        background-color: rgba(255, 255, 255, 0.13) !important;
      }

      &.drag-over {
        background-color: rgba(255, 255, 255, 0.53) !important;
      }

      .focussed-modifier {
        opacity: 1 !important;
        background-color: rgba(0, 0, 0, 0.04);
      }

      .modifier {
        pointer-events: none;
        opacity: 0;
        margin-right: 0;
        margin-left: auto;
        text-transform: none;

        &.isMobile {
          pointer-events: all;
          opacity: 1;
        }

        .dropdown-item svg {
          margin-right: 5px;
          width: 17px;
        }

        .btn {
          background: none;
          border: none;
          padding: 0;
          margin: 0;
          padding-bottom: 0.6rem;
          line-height: 0px;
          width: 1.2rem;
          height: 1.1rem;
          border-radius: 1rem;

          &:hover {
            box-shadow: 0 0 2pt 1pt rgba(255, 255, 255, 0.5);
          }

          &:active {
            box-shadow: transparent;
            background: transparent;
            opacity: 1;
          }

          &:focus {
            box-shadow: 0 0 2pt 1pt rgba(255, 255, 255, 0.5);
          }

          &::after {
            content: none;
          }
        }
      }
    }

    a:hover,
    div > a:hover,
    div > div > a:hover,
    a:focus,
    div > a:focus,
    div > div > a:focus {
      background: rgba(255, 255, 255, 0.13);
      opacity: 1;

      .modifier {
        pointer-events: all;
        opacity: 1;
      }
    }

    a.active,
    div > a.active,
    div > div > a.active {
      color: #ffffff;
      opacity: 1;
      background: rgba(255, 255, 255, 0.23);

      .modifier {
        display: initial;
        opacity: 1;
      }
    }
  }
}

.longFolderName {
  width: 100%;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  p {
    margin-bottom: 0 !important;
  }
}
