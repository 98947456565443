@import '../../styles/mixins';

.footer {
  z-index: 99999;
  overflow: hidden;
  position: absolute;
  bottom: 0;
  height: 2rem;
  width: 100%;
  font-size: 0.75rem;
  padding: 0.2rem;
  border-top: 1px solid silver;
  color: inherit;
  text-align: center;

  &.dark,
  &.blue {
    color: white;
    border-color: rgba(255, 255, 255, 0.1);

    a {
      color: white;
    }

    a:hover {
      text-decoration: underline;
    }
  }

  &.dark {
    background-color: #292929;
  }

  &.blue {
    background-color: #3897e3;
  }
}
