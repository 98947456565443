@import '../../styles/mixins';

.admin-table-wrapeer {
  flex-grow: 1;

  .secondRow {
    height: calc(100% - 40px);
  }
  .admin-footer {
    display: flex;
    width: 100%;
    justify-content: space-between;
    .user-count {
      width: 1000%;
      font-size: 0.85rem;
      color: #9a9a9a;
      text-align: right;
      opacity: 0.75;
    }
  }
}
