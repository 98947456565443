@import '../../../../../styles/mixins';

:root {
  --borderBoxShadow: 0 14px 26px -12px rgb(153 153 153 / 42%), 0 4px 23px 0px rgb(0 0 0 / 12%),
    0 8px 10px -5px rgb(153 153 153 / 20%);
}

.sidebar-view {
  overflow: auto;
  
  color: #777;
  background: transparent;
  border-left: 1px solid rgba(0, 0, 0, 0.1);
}

.sidebar-view__highlights {
  list-style: 'none';
  padding: 0;
}

.highlight__location {
  margin-top: 0.5rem;
  text-align: right;
  font-size: 10px;
}

.highlight__image {
  overflow: auto;
  max-width: 300px;
  border: 1px dashed rgba(0, 0, 0, 0.1);

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    max-height: 200px;
  }
}

.sidebar-view__highlight {
  padding: 1rem;
  cursor: pointer;
  transition: background 150ms ease-in;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);

  a:hover {
    text-decoration: underline;
  }
}

.sidebar-view__highlight:hover {
  background: rgba(0, 0, 0, 0.04);
}

.annotationsContainer {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  padding-inline: 10;
  height: calc(100% - 6rem);
}

.annotationsScrollbarContainer,
.summariesScrollbarContainer {
  flex-grow: 1;
  overflow: auto;
  /* Use overflow properties directly */
}

blockquote {
  font-size: 0.9rem;
  padding: 0;
  margin: 0;
  quotes: '\201c''\201d';
}
blockquote:before {
  content: open-quote;
}
blockquote:after {
  content: close-quote;
}

.description-class {  
  display: flex;
  flex-grow: 1;
  padding: 1rem;
  padding-left: 0.25rem;
}

.filterAnnotationsButton {
  background-color: white !important;
  box-shadow: 0 2px 2px 0 rgb(153 153 153 / 14%), 0 3px 1px -2px rgb(153 153 153 / 20%),
    0 1px 5px 0 rgb(153 153 153 / 12%);
}

.filterAnnotationsButton:hover {
  cursor: pointer;
  box-shadow: var(--borderBoxShadow);
}

.popper-shadow {
  box-shadow: var(--borderBoxShadow);
  z-index: 1;
}

.clearAll {
  margin-top: 100%;
  text-align: center;
  color: lightgrey;
}
