@import '../../styles/mixins';

.modal {
  button:disabled {
    cursor: default;
  }

  .invalid-feedback {
    position: absolute;
  }

  // for boostrap col and rows
  .col,
  .col-sm-4,
  .col-sm-8 {
    margin-bottom: 18px;
    min-width: 200px;
  }

  .col:empty {
    margin: 0;
    padding: 0;
    height: 0;
    width: 0;
    min-width: 0;
  }

  .form-group .row {
    margin-bottom: 0 !important;
  }

  @media (min-width: 576px) {
    .modal-dialog {
      max-width: 800px;
    }
  }
}

.modal-wide {
  @media (min-width: 576px) {
    .modal-dialog {
      max-width: 85%;
    }
  }
}
