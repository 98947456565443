@import '../../styles/mixins';

.sidebar {
  position: relative;
  width: 280px;
  min-width: 280px;
  flex-shrink: 0;
  display: block;
  z-index: 1;
  color: #fff;
  font-weight: 200;
  background-size: cover;
  background-position: center center;

  -webkit-transition-property: top, bottom, margin-left;
  transition-property: top, bottom, margin-left;
  -webkit-transition-duration: 0.2s, 0.2s;
  transition-duration: 0.2s, 0.2s;
  -webkit-transition-timing-function: linear, linear;
  transition-timing-function: linear, linear;
  -webkit-overflow-scrolling: touch;

  .ps__thumb-y {
    background-color: rgba(255, 255, 255, 0.5);
    opacity: 1;
  }

  .ps__rail-y:hover > .ps__thumb-y,
  .ps__rail-y:focus > .ps__thumb-y,
  .ps__rail-y.ps--clicking .ps__thumb-y {
    background-color: rgba(255, 255, 255, 0.3);
    opacity: 1;
  }

  .nav {
    margin-top: 15px;
    display: block;
  }

  .sidebar-wrapper {
    position: relative;
    height: calc(100vh - 61px);
    overflow: auto;
    width: 100%;
    z-index: 4;
    padding: 0 10px;
  }

  .logo {
    z-index: 4;
    padding: 10px 30px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.4);
    color: white;

    a {
      color: #ffffff;
    }
  }
}

.removeSidebar {
  margin-left: -280px;
}

@media (max-width: 991px) {
  .sidebar {
    margin-left: 0;
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: auto;

    @include transform-translate-x(280px);
    @include transition(0.33s, cubic-bezier(0.685, 0.0473, 0.346, 1));
  }
}
