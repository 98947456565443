@import '../../styles/mixins';

.reddot::after {
  position: absolute;
  content: '';
  border-radius: 50%;
  width: 0.5rem;
  height: 0.5rem;
  background-color: #FF0000;
  margin-left: 4.5rem;
  transition-delay: 0.2s;
  transition-property: margin-left;
}
