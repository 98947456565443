@import '../../styles/mixins';

.common-top-notification-wrapper {
  position: absolute;
  z-index: 101;
  top: 0;
  left: 0;
  right: 0;
  background: whitesmoke;
  text-align: center;
  line-height: 2.5;
  overflow: hidden;
  -webkit-box-shadow: 0 0 5px black;
  -moz-box-shadow: 0 0 5px black;
  box-shadow: 0 0 5px black;
  opacity: 0.85;
  pointer-events: none;
  display: none;
  color: black;
  font-weight: 500;
  align-items: center;
  font-size: 1.1rem;
  position: sticky;
  height: 2.5rem;
  margin-top: -2.5rem;

  .message {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;

    .warning {
      color: orange;
    }
  }

  .button {
    cursor: pointer;
    pointer-events: all;
    opacity: 0.75;

    &:hover {
      opacity: 1;
    }
  }

  svg {
    margin-left: 1rem;
    margin-right: 1rem;
  }

  &.show {
    display: flex;
  }
}
