@import '../../../../../styles/mixins';

.viewing-viewer-error {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  justify-content: center;
  text-align: center;
}

.no-compute-text {
  font-size: medium;
}
