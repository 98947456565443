@import '../../styles/mixins';

.logo {
  position: relative;
  white-space: nowrap;

  .simple-text {
    text-transform: uppercase;
    padding: 5px 0px;
    display: block;
    font-size: 18px;
    font-weight: 400;
    line-height: 30px;
  }

  p {
    float: left;
    font-size: 20px;
    margin: 10px 10px;
    color: white;
    line-height: 20px;
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  }

  a.logo-mini {
    float: left;
    text-align: center;
    width: 30px;
    margin-right: 15px;

    img {
      width: 40px;
      margin-left: -3px;
      display: block;
      margin-top: 2px;
    }
  }

  a.logo-normal {
    display: block;
    width: 15rem;
  }

  .logo-img {
    width: 34px;
    display: inline-block;
    height: auto;
    margin-left: -2px;
    margin-top: -2px;
    margin-right: 10px;
    border-radius: 30px;
    text-align: center;
  }
}

.logo-tim {
  border-radius: 50%;
  border: 1px solid #333;
  display: block;
  height: 61px;
  width: 61px;
  float: left;
  overflow: hidden;

  img {
    width: 60px;
    height: 60px;
  }
}
