@import '../../styles/mixins';

.conference-custom-viewer-multi-action-button {
  .fab-container {
    display: flex;
    flex-direction: column;
    pointer-events: none;

    &.up {
      button {
        pointer-events: all;
        margin-top: 0.6rem;
      }

      button:not(:last-child) {
        // remove to make blue
        background-color: white;
        color: black;

        margin-top: 0.3rem;
        pointer-events: none;
        opacity: 0;
        -webkit-transform: translateY(50px);
        -ms-transform: translateY(50px);
        transform: translateY(50px);
      }

      &.open {
        pointer-events: all;

        button {
          pointer-events: all;
          box-shadow: 0 4px 10px 0 #666;
          transform: rotate(45deg) scale(1.1);
          opacity: 1;
        }

        button:last-child::after {
          margin-left: 100rem;
          transition-delay: 0s;
          transition-property: margin-left;
        }

        button:not(:last-child) {
          opacity: 0.5;
          -webkit-transform: none;
          -ms-transform: none;
          transform: none;

          &:disabled {
            opacity: 0.3;
          }

          &:hover {
            box-shadow: 0 4px 10px 0 #666;
            transform: scale(1.1);
            opacity: 1;
            &:disabled {
              opacity: 0.3;
              transform: scale(1);
            }
          }
        }
      }

      button:nth-last-child(1) {
        -webkit-transition-delay: 25ms;
        transition-delay: 25ms;
      }

      button:not(:last-child):nth-last-child(2) {
        -webkit-transition-delay: 20ms;
        transition-delay: 20ms;
      }

      button:not(:last-child):nth-last-child(3) {
        -webkit-transition-delay: 40ms;
        transition-delay: 40ms;
      }

      button:not(:last-child):nth-last-child(4) {
        -webkit-transition-delay: 60ms;
        transition-delay: 60ms;
      }

      button:not(:last-child):nth-last-child(5) {
        -webkit-transition-delay: 60ms;
        transition-delay: 60ms;
      }
    }

    &.down {
      button {
        pointer-events: all;
        margin-bottom: 0.6rem;
        z-index: 2;
      }

      button:not(:first-child) {
        // remove to make blue
        background-color: white;
        color: inherit;
        z-index: 1;

        margin-bottom: 0.3rem;
        pointer-events: none;
        opacity: 0;
        -webkit-transform: translateY(-50px);
        -ms-transform: translateY(-50px);
        transform: translateY(-50px);
      }

      &.open {
        pointer-events: all;

        button {
          pointer-events: all;
          box-shadow: 0 4px 10px 0 #666;
          transform: rotate(45deg) scale(1.1);
          opacity: 1;
        }

        button:not(:first-child) {
          opacity: 0.5;
          -webkit-transform: none;
          -ms-transform: none;
          transform: none;

          &:disabled {
            opacity: 0.3;
          }

          &:hover {
            box-shadow: 0 4px 10px 0 #666;
            transform: scale(1.1);
            opacity: 1;
            &:disabled {
              opacity: 0.3;
              transform: scale(1);
            }
          }
        }
      }

      button:nth-child(1) {
        -webkit-transition-delay: 25ms;
        transition-delay: 25ms;
      }

      button:not(:first-child):nth-child(2) {
        -webkit-transition-delay: 20ms;
        transition-delay: 20ms;
      }

      button:not(:first-child):nth-child(3) {
        -webkit-transition-delay: 40ms;
        transition-delay: 40ms;
      }

      button:not(:first-child):nth-child(4) {
        -webkit-transition-delay: 60ms;
        transition-delay: 60ms;
      }

      button:not(:first-child):nth-child(5) {
        -webkit-transition-delay: 80ms;
        transition-delay: 80ms;
      }
    }
  }
}
