@import '../../styles/mixins';

.document-drop-zone {
  .drag {
    background-color: rgba(255, 255, 255, 0.23) !important;
  }

  .drag-over {
    background-color: rgba(255, 255, 255, 0.63) !important;
  }
}
