@import '../../styles/mixins';

.case-folder-modal {

}

.agree-warning {
    position: absolute;
    right: 100px;
    color: crimson;
    font-weight: bold;
}