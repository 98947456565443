@import '../../styles/mixins';

.common-collapsible {
  display: flex;
  height: 12px;
  padding: 0;
  cursor: pointer;
  margin-bottom: 10px;

  .text {
    display: flex;
    align-items: center;
    color: #7ba0f4;
    white-space: nowrap;
  }

  .line {
    border-top: 1px solid #7ba0f4;
    // z-index: 2;
    margin-top: 6px;
    height: 0;
    width: 100%;
    margin-left: 10px;
    margin-right: 5px;
  }

  .icon {
    font-size: 1.5rem;
    width: 30px;
    padding: 0.2rem;
    color: #7ba0f4;
    text-align: center;
    margin-top: -10px;
  }
}
