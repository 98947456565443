@import '../../../styles/mixins';

.common-table {
  display: contents;

  .no-selection {
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    user-select: none;
  }

  th {
    padding: 0 !important;

    & .th-content {
      padding: 12px 8px;
      padding-bottom: 5px;
      height: 100%;
    }

    & > .column-header {
      cursor: pointer;
      &:hover {
        background-color: rgba(0, 0, 0, 0.05);
      }
    }
  }

  tr {
    &.selected {
      background-color: rgba(0, 0, 0, 0.1) !important;
    }

    &.selectedAnnotation {
      background-color: rgba(161, 211, 253, 0.275);
    }

    .draggable {
      cursor: move;
      display: inherit;
    }
  }

  .ps__rail-y,
  .ps__rail-x {
    z-index: 1000;
  }

  .no-match {
    text-align: center;
    width: 100%;
    height: 100%;
    align-items: center;
    display: flex;
    justify-content: center;
    font-size: 1.5rem;
  }

  .sticky {
    th {
      position: sticky;
      top: 0;
      background: white;
      z-index: 3;
    }

    tbody {
      position: absolute;
      // top: 35px;
    }
  }

  .ps {
    overflow: auto !important;

    /* Hide scrollbar for Chrome, Safari and Opera */
    &::-webkit-scrollbar {
      display: none;
    }

    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
  .tab-align {
    text-align: left;
    padding-left: 1rem !important;
  }
}
