@import '../../../styles/mixins';

.search {
  .loading {
    animation: glow 0.8s infinite alternate;
    height: 100%;
  }
  .disableFilter {
    display: none !important;
  }

  .MuiSvgIcon-colorPrimary {
    color: gray;
  }

  .filterButton {
    color: gray;
    padding-top: 0.2rem;
  }
  .filterButton:hover {
    cursor: pointer;
  }

  @keyframes glow {
    from {
      box-shadow: 0 0 4px -2px #7ba0f4;
    }
    to {
      box-shadow: 0 0 4px 2px #7ba0f4;
    }
  }
}

.clickToSearch {
  :hover {
    cursor: pointer;
  }
}
