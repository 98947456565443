@import '../../styles/mixins';

.search-cases {
  display: flex;
  justify-content: center;
  align-items: center;
}

.search-box-size {
  width: 16rem;
  color: black;
}

.search-container {
  &::before {
    left: 0;
    right: 0;
    bottom: 0;
    content: '\00a0';
    position: absolute;
    transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-bottom: 1px solid rgba(0, 0, 0, 0.42);
    pointer-events: none;
    border-color: #d2d2d2 !important;
    border-width: 1px !important;
  }

  display: inline-flex;
  position: relative;

  &::after {
    left: 0;
    right: 0;
    bottom: 0;
    content: '';
    position: absolute;
    transform: scaleX(0);
    transition: transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    border-bottom: 2px solid #3a68ed;
    pointer-events: none;
  }

  &.focused::after {
    transform: scaleX(1);
  }

  > .form-control {
    font: inherit;
    color: currentColor;
    width: 100%;
    border: 0;
    height: 1.1875em;
    margin: 0;
    display: block;
    padding: 6px 0 7px;
    min-width: 0;
    background: none;
    box-sizing: content-box;
    -webkit-tap-highlight-color: transparent;
  }
}
