@import '../../styles/mixins';

.case-table-and-viewer {
  display: flex;
  width: 100%;
  height: 100%;
}

.gutter.gutter-horizontal {
  cursor: col-resize;
  display: flex;
  align-items: center;
  justify-content: center;
}

.gutter.gutter-horizontal:after {
  content: '\2807';
  font-size: 2rem;
  color: gray;
  padding-left: 0.5rem;
}

.gutter.gutter-horizontal:hover {
  background-color: #e2e2e2;
}

.no-gutter .gutter.gutter-horizontal {
  display: none;
}

.no-gutter .gutter.gutter-horizontal:after {
  content: '';
}

.reverse {
  flex-direction: row-reverse;
}
