@import '../../styles/mixins';

.nav-bar-links {
  display: flex;
  align-items: center;

  &.dark,
  &.blue {
    .d-l-none {
      .nav-link {
        color: white;
      }
    }
  }

  .d-l-none {
    .nav-link {
      padding: 0 !important;
      margin: 0.5rem;
      display: flex;

      &.dropdown-toggle::after {
        align-self: center;
        vertical-align: 0;
      }
    }
  }

  .dropdown-menu {
    visibility: hidden;
    opacity: 0;
    display: block;
    transform: scale(0.5);
    transition: all 0.3s cubic-bezier(0.34, 1.61, 0.7, 1);

    &.show {
      opacity: 1;
      visibility: visible;
      transform: none;
      transform-origin: center top;
      transition: opacity 283ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        transform 188ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    }

    .dropdown-item.hover,
    .dropdown-item:hover {
      background-color: #edf2ff;
    }
    .dropdown-item.active,
    .dropdown-item:active {
      background-color: #7ba0f4;
    }
  }

  .divider {
    display: none;
  }

  p {
    margin: 0;
  }

  .divider {
    display: none;
  }

  p {
    margin: 0;
  }

  .notification {
    position: absolute;
    background-color: #fb404b;
    text-align: center;
    border-radius: 10px;
    min-width: 18px;
    padding: 0 5px;
    height: 18px;
    font-size: 12px;
    color: #ffffff;
    font-weight: bold;
    line-height: 18px;
    top: -10px;
    left: 12px;
    opacity: 0.75;
  }

  .sidebar & {
    &:not(.show) {
      display: inherit;
    }

    .d-none.nav-item {
      margin: 5px;
    }

    .dropdown-item {
      padding: 0.5rem 1rem;
      &:focus {
        background-color: rgba(255, 255, 255, 0.23);
      }
      &:hover {
        background-color: rgba(255, 255, 255, 0.13);
      }
    }

    .divider,
    .d-none {
      display: inherit !important;
    }

    .d-l-none {
      display: none;
    }

    .notification {
      position: relative;
      background-color: inherit;
      text-align: inherit;
      border-radius: inherit;
      min-width: inherit;
      padding: inherit;
      height: inherit;
      font-size: inherit;
      color: inherit;
      font-weight: inherit;
      line-height: inherit;
      top: inherit;
      left: inherit;
      margin-left: 1rem;
      opacity: 1;
    }

    svg {
      font-size: 16px;
    }

    .dropdown-menu {
      transition: inherit;
    }
  }
}
