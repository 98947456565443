@import '../../styles/mixins';

section.case-file-drop-zone {
  //pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  &:focus {
    outline: none;
  }

  .upload-layer {
    padding-bottom: 10px;
    background-color: white;
    opacity: 0.9;

    .header {
      padding: 0px 20px;
      display: flex;
      justify-content: space-between;
      height: 50px;

      div {
        align-self: center;
      }
    }

    .body {
      padding: 0px 10px;
      overflow: auto;
      height: calc(100% - 50px);

      .file-container {
        display: flex;
        padding: 10px;

        svg {
          font-size: 2.2rem;
          align-self: center;
        }

        .progress-container {
          display: flex;
          flex-direction: column;
          margin-left: 15px;
          width: 100%;

          .title-container {
            display: flex;
            justify-content: space-between;

            svg {
              font-size: 1rem;
              align-self: center;
            }
          }
        }
      }
    }
  }

  .drag-layer {
    &:focus {
      outline: none;
    }

    height: 100%;
    margin: auto;
    border-radius: 0.25rem;
    transition: 0.3s all;
    -moz-transition: 0.3s all;
    -ms-transition: 0.3s all;
    -o-transition: 0.3s all;
    -webkit-transition: 0.3s all;

    .border-box {
      padding: 1rem;
      height: 100%;
      & > div {
        border: 1px dashed;
        opacity: 0.75;
        border-radius: 0.25rem;
        height: 100%;

        p {
          display: flex;
          flex-direction: column;
          align-items: center;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);

          svg {
            font-size: 2rem;
          }
        }
      }
    }
    .border-box-modal {
      padding: 0.5rem;
      height: 100%;
      & > div {
        border: 0.5px dashed;
        opacity: 0.75;
        border-radius: 0.25rem;
        height: 100%;

        p {
          display: flex;
          flex-direction: row;
          align-items: center;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);

          svg {
            margin-right: 0.5rem;
            font-size: 1.5rem;
          }
        }
      }
    }

    &.drag {
      background-color: white;
      opacity: 0.85;
    }

    &.drag-over {
      font-size: 1.2rem;
      opacity: 0.9;

      svg {
        font-size: 2.5rem;
      }
    }
  }
}

.tooltip {
  opacity: 1 !important;
  visibility: visible;
  display: inherit;
  z-index: 9999;
}

.file-stats-wrapper {
  flex-direction: column;
  padding-top: 1%;
}

.file-stats-row {
  display: flex;
  flex-direction: row;
  gap: 10px;
}