@import './mixins';

@font-face {
  font-family: 'Montserrat-Bold';
  src: url(./fonts/Montserrat-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'Montserrat-Regular';
  src: url(./fonts/Montserrat-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Montserrat-SemiBold';
  src: url(./fonts/Montserrat-SemiBold.ttf) format('truetype');
}

// Here you put all global css rules.s
*,
*::before,
*::after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

html,
body {
  overflow: hidden;
}

html,
body,
#root {
  // overflow: auto;
  border: 0;
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: 'Montserrat-Regular', Verdana;
  font-size: 12px;
}

// Chrome focus rectangle removal
input:focus,
textarea:focus,
button:focus,
select:focus,
div:focus,
a:focus,
.btn:focus,
.btn.focus {
  outline: none;
  box-shadow: none;
}

//scrollbar color
.ps .ps__rail-x:hover,
.ps .ps__rail-y:hover,
.ps .ps__rail-x:focus,
.ps .ps__rail-y:focus,
.ps .ps__rail-x.ps--clicking,
.ps .ps__rail-y.ps--clicking {
  background-color: transparent;
}

//scrollbar min-height
.ps__thumb-y {
  min-height: 20px;
}

// making toasts go over modals
.react-toast-notifications__container,
.popover {
  z-index: 99999 !important;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #dddddd;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #dddddd;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #dddddd;
}
