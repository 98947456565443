@import '../../styles/mixins';

.case-nothing-selected-page {
  padding: 15rem 5rem 15rem 5rem;
  background-color: white;
  text-align: center;
  height: 100%;
  font-size: 1.5rem; 
  display: flex;
  align-items: center;
  justify-content: center;
}
