@import '../../styles/mixins';

.home-information {
  width: 100%;
  padding: 2rem;
  background-color: #0288d1;
  color: white;

  .pointer {
    text-align: left;
    margin: 1rem;

    svg {
      margin-right: 0.5rem;
    }

    h6 {
      opacity: 0.6;
    }

    span {
      font-size: 0.75rem;
    }
  }
}
