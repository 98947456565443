@import '../../styles/mixins';

.toggle-button {
  display: flex;

  input[type='checkbox'] {
    opacity: 0;
  }

  .checkbox {
    position: absolute;
    width: 100%;
    height: 100%;
    cursor: pointer;
    // z-index: 5;
    z-index: 1;

    display: inline-block;
  }

  .label {
    margin-right: 1rem;
    display: inline-block;
  }

  input[type='checkbox'] + .toggle-wrapper {
    height: 2rem;
    border: 0.125rem solid;
    border-color: #ced4da;
    border-radius: 1rem;
    width: 4rem;
    vertical-align: middle;
    position: relative;
    // z-index: 4;
    display: inline-block;
    .checkbox-toggle {
      transition: all 0.3s cubic-bezier(0.33, 1, 0.66, 1);
      position: absolute;
      border-radius: 1rem;
      background-color: #ced4da;
      top: 0.1rem;
      left: 0.1rem;
      width: 1.5rem;
      height: 1.5rem;
      display: inline-block;
    }
  }

  input[type='checkbox']:checked + .toggle-wrapper {
    border-color: #7ba0f4;

    .checkbox-toggle {
      left: 2.1rem;
      background-color: #7ba0f4;
    }
  }

  input[type='checkbox']:disabled + .toggle-wrapper {
    cursor: auto;
    border-color: #ced4da;

    .checkbox-toggle {
      background-color: #ced4da;
    }
  }
}
