@import '../../../../../styles/mixins';

.isEvidenceViewEnabled {
  .viewing-custom-viewer-toolbox {
    .document-options {
      button,
      .pageNumber {
        background-color: white;
        border-radius: 10%;
        color: black;
      }
    }
  }
}

.evidence {
  .viewing-custom-viewer-toolbox {
    .presenting-info {
      font-size: 1.2rem;
      padding: 0.4rem;
    }
    .document-options {
      button,
      .pageNumber {
        width: 2rem;
        height: 2rem;
        background-color: white;
        border-radius: 10%;
        color: black;
      }

      &.top-left {
        top: 2rem;
        left: 0.2rem;
      }

      &.top-right {
        right: 0.2rem;
      }

      &.right {
        right: 0.2rem;
      }

      &.left {
        left: 0.2rem;
      }
    }
  }
}

.viewing-custom-viewer-toolbox,
.conference-conference-action-buttons {
  .presenting-info {
    font-size: 0.7rem;
    background-color: black;
    opacity: 0.6;
    padding: 10px;
    color: white;
  }

  .document-options {
    pointer-events: none;
    position: absolute;
    z-index: 2;

    &.toolbox {
      width: 50%;
      height: 30%;
      opacity: 0;
      transition: opacity 0.5s;
      cursor: inherit;
      pointer-events: none;

      display: flex;
      bottom: 1rem;
      left: 25%;
      justify-content: center;

      &.show {
        opacity: 1;
      }

      button {
        align-self: flex-end;
        width: 2rem;
        height: 2rem;
        border-radius: 10%;
        box-shadow: 0 4px 6px 0 #666;
        transition: all 0.1s ease-in-out;
        padding: 0;

        font-size: 1rem;

        margin: 0.3rem;
      }

      .pageNumber {
        align-self: flex-end;
        width: auto;
        height: 2rem;
        border-radius: 10%;
        box-shadow: 0 4px 6px 0 #666;
        transition: all 0.1s ease-in-out;

        font-size: 1rem;

        margin: 0.3rem;
        padding: 0.3rem;

        & > div {
          display: inherit !important;

          input {
            background: none;
            border: none;
            margin-right: 0.1rem;
            color: inherit;
          }
        }

        span {
          align-self: center;
          white-space: nowrap;
        }
      }
    }

    button,
    .pageNumber {
      pointer-events: all;
      width: 3rem;
      height: 3rem;
      background-color: #4567e5;
      border-radius: 50%;
      border: none;
      box-shadow: 0 4px 6px 0 #666;
      transition: all 0.1s ease-in-out;

      font-size: 1.5rem;
      color: white;
      text-align: center;

      margin: 1rem;
      margin-top: 0.3rem;
      margin-bottom: 0.3rem;
      opacity: 0.75;

      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0;

      svg {
        align-self: center;
      }

      &:disabled,
      &.noTransform {
        opacity: 0.3;
        cursor: default;
      }

      &:hover,
      &.selected {
        box-shadow: 0 4px 10px 0 #666;
        transform: scale(1.1);
        opacity: 1;
        &:disabled,
        &.noTransform {
          box-shadow: 0 4px 6px 0 #666;
          opacity: 0.3;
          transform: scale(1);
        }
      }

      &.present {
        background-color: red;
        animation: blinker 1.2s linear infinite;
      }

      @keyframes blinker {
        50% {
          opacity: 1;
        }
      }
    }

    &.top-left {
      display: flex;
      flex-direction: column;
      left: 1rem;
      top: 1rem;
    }

    &.top {
      display: flex;
      flex-direction: column;
      left: calc(50% - 2rem);
      top: 1rem;
    }

    &.top-right {
      display: flex;
      flex-direction: column;
      right: 1rem;
      top: 1rem;
      // so that button will go over side panel
      z-index: 3;
    }

    &.right {
      display: flex;
      flex-direction: column;
      right: 1rem;
      bottom: 1rem;

      .pageNumber {
        align-self: flex-end;
        width: auto;
        height: 2rem;
        border-radius: 10%;
        box-shadow: 0 4px 6px 0 #666;
        transition: all 0.1s ease-in-out;

        font-size: 1rem;

        margin: 0.3rem;
        padding: 0.3rem;

        & > div {
          display: inherit !important;

          input {
            background: none;
            border: none;
            margin-right: 0.1rem;
            color: inherit;
          }
        }

        span {
          align-self: center;
          white-space: nowrap;
        }
      }
    }

    &.left {
      display: flex;
      flex-direction: column;
      left: 1rem;
      bottom: 1rem;
      opacity: 0;
      transition: opacity 0.5s;

      &.show {
        opacity: 1;
      }
    }
  }
}
