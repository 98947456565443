@import '../../styles/mixins';

.common-error-page {
  padding-left: 2rem;
  padding-right: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: calc(100% - 12rem);
  color: white;
  background: linear-gradient(90deg, #4567e5 0%, #7ba0f4 100%);
  background-blend-mode: multiply;
  .simple-text {
    font-size: 2rem;
    color: white;
    text-decoration: underline;
    margin-bottom: 2rem;
  }

  .title {
    font-weight: 600;
    font-size: 4rem;
    line-height: 6.2rem;
    margin-bottom: 1rem;
    text-align: center;
  }

  .image {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 1rem;
  }

  .text {
    font-size: 1.8rem;
    margin-bottom: 0.5rem;
    font-weight: 500;
    line-height: 3rem;
    text-align: center;
  }

  .link-text {
    font-size: 1.8rem;
    font-weight: 500;
    line-height: 3rem;
    margin-bottom: 0.5rem;
    text-align: center;
    color: white;

    .link {
      text-decoration: underline;
      font-weight: 700;
    }
  }
}
.footer-container {
  position: absolute;
  bottom: 0;
  width: 100%;
}

@media (max-width: 767px) {
  .footer-container {
    position: relative;
    width: 100%;
  }
  .common-error-page {
    height: 100%;
  }
}

@media (max-width: 550px) {
  .common-error-page {
    .title {
      font-size: 3rem;
      font-weight: 600;
      line-height: 4rem;
    }
    .text {
      font-size: 1.6rem;
      font-weight: 500;
      line-height: 2.5rem;
    }
    .link-text {
      font-size: 1.6rem;
      font-weight: 500;
      line-height: 2.5rem;
    }
  }
}
